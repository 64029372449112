
/*This style sheet should only contain Checkbox and Radio button styles */

.checkbox {
    margin-bottom: 5px;
    padding-left: 30px;
}

    .checkbox label {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding-left: 0;
    }

    .checkbox input:disabled {
        background-color: red;
    }

.form-group > .checkbox label > input[type='checkbox'] {
    top: 5px;
}

.checkbox.option-checkbox label > input[type='checkbox'] {
    top: 0 !important;
}

.checkbox label > input[type='checkbox'] {
    /*display: none;*/
    display:inline;
}

    .checkbox label > input[type='checkbox'] ~ span {
        line-height: 21px;
        padding: 2px;
        margin: 1px 1px 1px -1px;
    }

        .checkbox label > input[type='checked'] ~ span::before {
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 2px;
            content: '';
            display: inline-block;
            height: 20px;
            left: -30px;
            position: absolute;
            top: 0;
            width: 20px;
        }

    .checkbox label > input[type='checkbox']:checked ~ span::after {
        color: white;
        content: '\f00c';
        display: inline-block;
        font-family: 'FontAwesome';
        font-size: 11px;
        left: -30px;
        line-height: 21px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 20px;
    }

    .checkbox label > input[type='checkbox']:checked ~ span::before {
        border: 10px solid #15a4e5;
    }
    .checkbox label > input[type='checkbox'][disabled]:checked ~ span::before {
        border: 10px solid #cecece;
    }
    .checkbox label > input[type='checkbox'][disabled] ~ span::before {
        border: 10px solid #cecece;
    }
        .checkbox label > input[type='checkbox'] ~ span::before {
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 2px;
            content: '';
            display: inline-block;
            height: 20px;
            left: -30px;
            position: absolute;
            top: 0;
            width: 20px;
        }

    .checkbox label > input[type='checkbox']:checked ~ span {
        font-weight: bold !important;
    }

/*.checkbox label:has(input:checked) {
    font-weight: bold !important;
}*/

.checkbox label > input[type='checkbox']:checked::after {
    font-family: "FontAwesome";
    content: "\f00c";
    color: white;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    margin-left: -17px;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
    opacity: 0;
    z-index: 1;
}

    .checkbox input[type="checkbox"]:focus + label::before,
    .checkbox input[type="radio"]:focus + label::before {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    .checkbox input[type="checkbox"]:checked + label,
    .checkbox input[type="radio"]:checked + label {
        font-weight: bold;
    }

        .checkbox input[type="checkbox"]:checked + label::after,
        .checkbox input[type="radio"]:checked + label::after {
            font-family: "FontAwesome";
            content: "\f00c";
        }

    .checkbox input[type="checkbox"]:disabled + label,
    .checkbox input[type="radio"]:disabled + label {
        opacity: 0.65;
    }

        .checkbox input[type="checkbox"]:disabled + label::before,
        .checkbox input[type="radio"]:disabled + label::before {
            background-color: #eeeeee;
            cursor: not-allowed;
        }

.checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
    background-color: #15a4e5;
    border-color: #15a4e5;
}

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
    color: #fff;
}

.radio {
    margin-top: 10px;
    margin-bottom: 18px;
    outline: 0;
    padding-left: 0;
}

    .radio label {
        cursor: pointer;
        display: inline-block;
        margin-left: 0 !important;
        padding-left: 30px;
        position: relative;
        vertical-align: middle;
    }

        .radio label span::before {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 50%;
            content: "";
            cursor: pointer;
            display: inline-block;
            height: 24px;
            left: 0;
            top: -2px;
            outline: 0;
            position: absolute;
            -o-transition: border 0.15s ease-in-out;
            -webkit-transition: border 0.15s ease-in-out;
            transition: border 0.15s ease-in-out;
            width: 24px;
        }

        .radio label span::after {
            background-color: #555555;
            border: 1px solid #fff;
            border-radius: 50%;
            content: " ";
            cursor: pointer;
            display: inline-block;
            height: 14px;
            left: 3px;
            margin-left: 2px;
            margin-top: 1px;
            outline: 0;
            position: absolute;
            top: 2px;
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            /* ReSharper disable CssBrowserCompatibility */
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            /* ReSharper restore CssBrowserCompatibility */
            width: 14px;
        }

        .radio label::before,
        .radio label:focus,
        .radio label::before:focus,
        .radio:focus label::before {
            outline: 0;
        }

    .radio input[type="radio"] {
        display: inline-block;
        margin-left: -30px !important;
        margin-top: -2px !important;
    }

    .radio input[type="radio"],
    .radio-inline input[type="radio"] {
        cursor: pointer;
        height: 25px;
        margin-left: -22px;
        margin-top: -1px;
        width: 25px;
    }

        .radio input[type="radio"]:focus ~ span::before {
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }
        
      
        .radio input[type="radio"]:checked ~ span::after {
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }

        .radio input[type="radio"]:disabled ~ span {
            opacity: 0.65;
        }

            .radio input[type="radio"]:disabled ~ span::before {
                cursor: not-allowed;
            }

    .radio.radio-inline {
        margin-top: 0;
    }

.radio-primary input[type="radio"] ~ span::after {
    background-color: #337ab7;
}

.radio-primary input[type="radio"]:checked ~ span::before {
    border-color: #337ab7;
}

.radio-primary input[type="radio"]:checked ~ span::after {
    background-color: #337ab7;
}

.radio-danger input[type="radio"] ~ span::after {
    background-color: #d9534f;
}

.radio-danger input[type="radio"]:checked ~ span::before {
    border-color: #d9534f;
}

.radio-danger input[type="radio"]:checked ~ span::after {
    background-color: #d9534f;
}

.radio-info input[type="radio"] ~ span::after {
    background-color: #5bc0de;
}

.radio-info input[type="radio"]:checked ~ span {
    font-weight: bold;
}

.radio-info input[type="radio"]:checked ~ span::before {
    border: 7px solid #15a4e5;
    cursor: pointer;
    height: 24px;
    left: 0;
    outline: 0;
    width: 24px;
}

.radio-info input[type="radio"]:checked ~ span::after {
    background-color: #fff;
    border: 2px solid #1597c8;
    cursor: pointer;
    height: 14px;
    left: 3px;
    top: 2px;
    width: 14px;
}

.radio-warning input[type="radio"] ~ span::after {
    background-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked ~ span::before {
    border-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked ~ span::after {
    background-color: #f0ad4e;
}

.radio-success input[type="radio"] ~ span::after {
    background-color: #5cb85c;
}

.radio-success input[type="radio"]:checked ~ span::before {
    border-color: #5cb85c;
}

.radio-success input[type="radio"]:checked ~ span::after {
    background-color: #5cb85c;
}

.radio-spacer {
    margin: 0 20px 0 0;
}

.radio-inline.radio-spacer {
    margin-bottom: 15px;
}

input[type="checkbox"].styled:checked ~ span::after,
input[type="radio"].styled:checked ~ span::after {
    font-family: 'FontAwesome';
    content: "\f00c";
}

input[type="checkbox"] .styled:checked ~ span::before,
input[type="radio"] .styled:checked ~ span::before {
    color: #fff;
}

input[type="checkbox"] .styled:checked ~ span::after,
input[type="radio"] .styled:checked ~ span::after {
    color: #fff;
}


/*  ======= IE 10 ======= */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .input-group-addon,
    .input-group-btn {
        width: auto !important;
    }
}