#cookieSettingsPanel {
    text-align: right;
}

    #cookieSettingsPanel #ot-sdk-btn {
        background: none;
        border: none;
        padding: 0;
        font-size: 14px;
        color: #337ab7;
        cursor: pointer;
    }
    
    #cookieSettingsPanel li {
        display: inline;
        list-style-type: none;
    }

    #cookieSettingsPanel a {
        color: #337ab7;
    }

   #cookieSettingsPanel a:hover, #cookieSettingsPanel #ot-sdk-btn:hover {
            color: #23527c;
            text-decoration: underline;
        }

   
