//TODO: Use media queries for heights and widths

$formControlHeight: 34px;
$formControlBorder: 1px solid #ccc;
$formControlFocusBorderColor: #66afe9;
$formControlFocusBoxShadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
$formControlFocusOutline: 0 none;

body {
    padding-top: 50px;
    padding-bottom: 20px;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

.address-validation-result > .field-validation-error{
    white-space: normal
}

.bank-verification-result > .field-validation-error {
    white-space: normal;
}

.bank-verification-result {
    margin-bottom: 0;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
    //max-width: 280px;
}

label.small {
    font-weight: normal;
}

.form-control-phone {
    width: 185px;
}

.checkbox-inline > input[type=checkbox] {
    height: auto;
}

.radio-inline > input[type=radio] {
    height: auto;
}

label.checkbox-inline {
    font-weight: bold;
}

.well-legend {
    position: relative;
}

.well-legend .legend {
    border-top-right-radius: 0;
    background-color: #333;
    border: 1px solid #e1e1e8;
    border-radius: 0 4px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 21px;
    padding: 5px 8px;
    /*position: absolute;
    right: 0;
    top: 0;*/
    z-index: 10;
}

.input-base-field-differential {
}

.input-base-field-differential-type {
}

.input-base-field-differential-data {
}

div.field-label > div {
    text-overflow: ellipsis;
    overflow-x: hidden;
}

*:not(.no-truncation) > .control-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: visible;
}

.field-control-label {
    overflow-y: visible;
}

.form-control.readonly {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

label.form-control.readonly {
    margin-bottom: 0;
}

.field-options-single-selection .field-value {
    height: auto;
    min-height: 34px; 
    word-break: break-all;
}

label.checkbox-inline > span {
    text-overflow: ellipsis;
    /*white-space: nowrap;*/
    overflow-x: hidden;
    overflow-y: visible;
}

.summary-group {
    display: none;
    margin-top: 15px;
}

.collapsed .summary-group {
    display: block;
}

.collapsed .field-group:not(.summary-group) {
    display: none;
}

.expand-section {
    display: none;
}

.collapsed .collapse-section {
    display: none;
}

.collapsed .expand-section {
    display: block;
}

.conditional-display {
    display: none;
}

.conditional-display.show {
    display: inline;
}

.collapsed .conditional-display.show {
    display: none !important;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.underlined {
    text-decoration: underline;
}

.bold {
    font-weight: bold;
}

.field-add-section, .field-delete-section {
    margin-top: 20px;
}

.field-add-group, .field-delete-group {
    margin-bottom: 20px;
}

.hidden {
    display: none;
}

.navigation-button-container .btn-next-step, .navigation-button-container .btn-submit-step, .navigation-button-container .btn-previous-step {
    margin: 5px 0;
    padding: 11px 12px 8px 12px;
    width: 100%;
}

/* Styles for validation helpers
-----------------------------------------------------------*/
/*
.field-validation-error
{
    color: #ff0000;
}

.field-validation-valid
{
    display: none;
}

.input-validation-error
{
    border: 1px solid #ff0000;
    background-color: #ffeeee;
}

.validation-summary-errors
{
    font-weight: bold;
    color: #ff0000;
}
*/

.validation-summary-valid {
    display: none;
}

.rule-data.required + .field {
    input.input-field:not([type=checkbox]):not([type=radio]):not(.k-datepicker) {
        border-left: 2px solid red;
    }

    select.input-field {
        border-left: 2px solid red;
    }

    .radio label:first-child::before {
        border-left: 2px solid red;
        margin-left: -3px;
        content: "";
        position: relative;
        left: -31px;
        padding: 4px 0;
    }

    .displayOptionAsRequired::before {
        border-left: 2px solid red;
        margin-left: -3px;
        content: "";
        position: absolute;
        left: 0px;
        padding: 11px 0;
    }

    .k-widget.k-dropdown.input-field {
        border-left: 2px solid red;
    }
}

.k-dropdown-wrap {
    padding-right: 2em !important;
}

.k-widget.k-dropdown {
    width: auto;
}

/*div.form-group.field {
    *[data-val-required]:not(.readonly) {
        border-left: 2px solid red;
    }
}*/

/*div.form-group.field:not(.suppress-required) {
    *[data-val-requiredif]:not(.readonly) {
        border-left: 2px solid red;
    }
}*/

.radio-spacer {
    margin-left: 30px;
    margin-right: 30px;
    white-space: nowrap;
}

/************************Kendo UI Overrides************************/

.k-ff input.k-textbox.form-control {
    height: $formControlHeight;
    line-height: 1.42857;
    padding: 6px 12px;
    text-indent: inherit;
    display: block;
    width: 100%;
}


.k-autocomplete.form-control, .k-combobox.form-control, .k-datepicker.form-control, .k-timepicker.form-control, .k-datetimepicker.form-control, .k-colorpicker.form-control, .k-dropdown.form-control, .k-selectbox.form-control, .k-textbox.form-control, .k-toolbar .k-split-button.form-control {
    display: initial;
}

.k-numerictextbox.form-control {
    width: 100%;
}

.k-ff50 .k-timepicker input { /* Firefox 50 workaround */
    top: -2.27em;
}

.k-numeric-wrap {
    border-radius: 0 2px 2px 0;
}

.k-numeric-wrap::before {
    display: none !important;
}

.form-control > .k-picker-wrap:before {
    height: 1.9em; /* allow the picker icon to be slightly vertically off-center to keep control the same height as others so bootstrap wrapping works right */
}
.k-numeric-wrap .k-input.form-control {
    height: auto;
    border-radius: initial;
}



.k-autocomplete, .k-dropdown-wrap {
    border: none !important;
}

.k-textbox.input-field.form-control {
    border: $formControlBorder;
}

.k-textbox.input-field.form-control:focus {
    border-color: $formControlFocusBorderColor;
    box-shadow: $formControlFocusBoxShadow;
    outline: $formControlFocusOutline;
}

.input-group .form-control .k-numeric-wrap .form-control:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.k-textbox.input-field.form-control {
    display: block;
    border-radius: 2px;
}

div.autocomplete {
    span.k-autocomplete {
        display: inline-block;
    }
}

/******************************************************************/


/*******************************File Input*************************/
.fileinput-button {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.fileinput-button input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    /*transform: translate(-300px, 0) scale(4);*/
    /*font-size: 23px;*/
    direction: ltr;
    cursor: pointer;
}

.one-column-section {
    width: 100%;
    float: left;
}

.one-column-section-nofloat {
    width: 100%;
}

.two-column-section {
    width: 50%;
    float: left;
}

.four-column-section {
    width: 25%;
    float: left;
}
/******************************************************************/

/*****************************Theme Additional***********************/

.btn-print-pdf:hover {
    background: #5C7DA8;
    border-color: #5C7DA8 !important;
    color: white;
}

.btn-print-pdf:active {
    background: #294A75;
    border-color: #294A75 !important;
    color: white;
}

.btn-validate-send-to-merchant {
    border-color: #345D93;
    padding: 11px 31px 8px 26px;
    background: #345d93;
    border-radius: 4px;
    color: #fff;
    font: 15px/19px 'HelveticaNeue';
    outline: 0 !important;
}

.btn-validate-send-to-merchant:hover {
    background: #5C7DA8;
    border-color: #5C7DA8 !important;
    color: white;
}

.btn-validate-send-to-merchant:active {
    background: #294A75;
    border-color: #294A75 !important;
    color: white;
}

/*
.btn-send-to-merchant {
    border-color: #345D93;
    padding: 11px 31px 8px 26px;
    background: #345d93;
    border-radius: 4px;
    color: #fff;
    font: 15px/19px 'HelveticaNeue';
    outline: 0 !important;
}

.btn-send-to-merchant:hover {
    background: #5C7DA8;
    border-color: #5C7DA8 !important;
    color: white;
}

.btn-send-to-merchant:active {
    background: #294A75;
    border-color: #294A75 !important;
    color: white;
}
*/

.glyphicon.spinning {
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

span.input-group-addon {
    border: none;
    background: none;
    font-family: helveticaNeue;
    background-color: #c6c6c6;
}

.btn-page-view-toggle {
    border-color: #fff;
    padding: 11px 0 0 0;
    font: 12px/20px 'HelveticaNeue';
    color: #939393;
    outline: 0 !important;
    border: none;
}

.btn-page-view-toggle:hover {
    background-color: transparent;
    border-color: transparent;
    color: #939393;
    text-decoration: underline;
}

.page-view-toggle {
    position: relative;
    top: -35px;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #ccc;
  border-color: #ccc;
}

.form-label {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
}

.sub-group legend {
    font-size: 16px;
}

.checkbox label.compact > input[type='checkbox'] ~ span {
    padding: 0;
    margin: 0;
    display: inline;
}

.field-ownership-percentage {
    .form-control[disabled], .form-control[readonly] {
        color: #eee;
    }
}

.field-label.principal-header-title {
    text-align: center;
    margin-top: -19px;

    label {
        color: rgba(0, 0, 0, .85);
        font: bold 22px 'HelveticaNeue';
    }
}

.field-label.principal-header-parenthetical {
    text-align: center;

    label {
        color: rgba(0, 0, 0, .85);
        font: 14px/18px 'HelveticaNeue';
    }
}

/* beneficial owner confirmation */
#field-checked-9049-0:not(:checked) ~ span {
    color: red;
    font-weight: bold;
}

/* beneficial owner confirmation */
#field-checked-9049-0:disabled ~ span {
    color: #aaa;
    font-weight: bold;
}

div.blind {
    position: relative;
    width: 12.4em !important;

    > input.dual-blind-text {
        width: 12.4em !important;
    }

    > input::-ms-clear {
        display: none;
    }

    > span.input-field-masked {
        width: auto !important;

        > input.form-control.input-field.input-field-masked.k-textbox {
            width: 12.4em !important;
        }
    }

    > span.fa {
        position: absolute;
        left: 12.4em !important;
        margin-left: -1.73em;
        padding: 0 .3em;
        top: 0;
        line-height: 2.3em;
        z-index: 1;
        cursor: pointer;
        border: solid 1px #ccc;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;

        :hover {
            background-color: #ebebeb;
        }
    }

    > input.form-control.input-field.input-field-masked.k-textbox:focus + span.fa {
        border-top-color: #66afe9;
        border-right-color: #66afe9;
        border-bottom-color: #66afe9;
    }
}

.k-ff div.blind > span.fa {
    left: auto;
    right: 0;
}

/*****************************************************************/
/************************ BOOTSTRAP TIMEPICKER **********************/

.bootstrap-timepicker {
    position: relative;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
    left: auto;
    right: 0;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
    left: auto;
    right: 12px;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
    left: auto;
    right: 13px;
}

.bootstrap-timepicker .input-group-addon {
    cursor: pointer;
}

.bootstrap-timepicker .input-group-addon i {
    display: inline-block;
    width: 16px;
    height: 16px;
}

.bootstrap-timepicker-widget.dropdown-menu {
    padding: 4px;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
    display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
    border-bottom: 6px solid #FFFFFF;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
    left: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-left:after {
    left: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:before {
    right: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:after {
    right: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:before {
    top: -7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:after {
    top: -6px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #ffffff;
}

.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
    border-radius: 4px;
}

.bootstrap-timepicker-widget table {
    width: 100%;
    margin: 0;
}

.bootstrap-timepicker-widget table td {
    text-align: center;
    height: 30px;
    margin: 0;
    padding: 2px;
}

.bootstrap-timepicker-widget table td:not(.separator) {
    min-width: 30px;
}

.bootstrap-timepicker-widget table td span {
    width: 100%;
}

.bootstrap-timepicker-widget table td a {
    border: 1px transparent solid;
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 8px 0;
    outline: 0;
    color: #333;
}

.bootstrap-timepicker-widget table td a:hover {
    text-decoration: none;
    background-color: #eee;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border-color: #ddd;
}

.bootstrap-timepicker-widget table td a i {
    margin-top: 2px;
    font-size: 18px;
}

.bootstrap-timepicker-widget table td input {
    width: 25px;
    margin: 0;
    text-align: center;
}

.bootstrap-timepicker-widget .modal-content {
    padding: 4px;
}

.embed-signature-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-signature-container iframe, .embed-signature-container object, .embed-signature-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.opticard-giftcard-design-gold::after {
    content: url(https://ientryapp.io/Content/Images/Opticard/CardFaceGold.png);
}

.opticard-giftcard-design-silver::after {
    content: url(https://ientryapp.io/Content/Images/Opticard/CardFaceSilver.png);
}

.opticard-giftcard-design-box::after {
    content: url(https://ientryapp.io/Content/Images/Opticard/CardFaceBox.png);
}

.opticard-giftcard-design-stripes::after {
    content: url(https://ientryapp.io/Content/Images/Opticard/CardFaceStripes.png);
}

/*
.opticard-giftcard-design-gold::after {
    content: url(http://placehold.it/320x210/000000?text=Gold);
}

.opticard-giftcard-design-silver::after {
    content: url(http://placehold.it/320x210/000044?text=Silver);
}

.opticard-giftcard-design-box::after {
    content: url(http://placehold.it/320x210/004400?text=Box);
}

.opticard-giftcard-design-stripes::after {
    content: url(http://placehold.it/320x210/440000?text=Stripes);
}
*/

@media (min-width: 767px) {
    .bootstrap-timepicker-widget.modal {
        width: 200px;
        margin-left: -100px;
    }
}

/*****************************************************************/

.rule-data {
    display: none !important;
}

.document-link::before {
    content: ", ";
}

.document-link.last::before {
    content: ", and ";
}

.document-link.last:first-child::before {
    content: " and ";
}

/***************************** Merker ******************************/

.show-loading {
}

/*******************************************************************/

/************************** Resolution *****************************/
#resolutionNotSupported {
    display: none;
}

/*******************************************************************/
@media print {
}



@media (min-width: 768px) {
    .form-group.field.field-fee {
        min-height: 104px;
        margin-bottom: 0px;
    }

    .form-group.field.field-integer {
        min-height: 104px;
        margin-bottom: 0px;
    }

    .form-group.field.field-rate {
        min-height: 104px;
        margin-bottom: 0px;
    }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
    
}

@media (max-width: 1199px) {
    .navigation-button-container {
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) {
}

/*Firefox fixes*/
@-moz-document url-prefix() {
    .k-timepicker {
        white-space: normal !important;
    }
}

/*Peripheral Styling*/
.table > tbody > tr.peripheral-grid-container > td {
    border-top: 0;
    padding-top: 0;
    padding-left: 3px;
}
.dropdown-peripheral {
    height: 30px;
    padding-left: 5px;

    .dropdown {
        position: absolute;
    }
}

.dropdown-menu-peripheral {
    margin-top: 0;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: #ccc;
    cursor: not-allowed;
}

.hovericon.glyphicon {
    visibility: hidden;
    width: 0;
    left: -1.5em;
}

.modal-input {
    input {
        cursor: pointer;
        background-color: rgb(235, 235, 228);
        border: 1px solid #bbb;
        padding: 2px 1px;
    }

    input:hover + .hovericon {
        visibility: visible;
    }

    .submit-modal-input {
        visibility: hidden;
    }

    .cancel-modal-input {
        visibility: hidden;
    }
}

.modal-input.modal-input-active {
    input {
        cursor: default;
        background-color: white;
    }

    input:hover + .hovericon {
        visibility: hidden;
    }

    .submit-modal-input {
        visibility: visible;
    }

    .cancel-modal-input {
        visibility: visible;
    }
}

.field-red-text {
    color: red;
}

.field-width-100 input {
    width: 100%;
}

.field-width-100 div {
    width: 100%;
}
.equipment-terminal-field-width {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}
.equipment-terminal-field-width-optiontext {
    width: 75%;
    text-align: left;
    float: left;
}
.equipment-terminal-field-width-optionprice::before {
    content: "$";
}
.equipment-terminal-field-width-optionprice {
    width: 25%;
    text-align: right;
    float: right;
}

