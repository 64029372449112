/* ReSharper disable CssNotResolved */
@font-face {
    font-family: 'HelveticaNeue Light';
    src: url("fonts/HelveticaNeueLTStd-Th.otf");
    font-weight: 300;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url("fonts/HelveticaNeueLTStd-Roman.otf");
    font-weight: 400;
}


@font-face {
    font-family: 'HelveticaNeue Italic';
    src: url("fonts/HelveticaNeueLTStd-LtIt.otf");
    font-weight: 400;
}

/*General*/
body {
    padding-top: 0 !important;
}

.wizard-heading {
    text-align: center;
}

.wizard-heading h1 {
    color: #345D93;
    /*display: inline-block;*/
    font: 27px 'HelveticaNeue Light';
    /*vertical-align: top;*/
}

.wizard-logo {
    height: 90px;
    overflow: hidden;
}

.wizard-logo > img {
    max-height: 100%;
    max-width: 100%;
}

.wizard-steps-navbar {
    margin-bottom: 30px;
}

header .container {
    padding-left: 0;
    padding-right: 30px;  
}

.wizard-thick-line hr {
    margin-top: 0;
    color: #EAEAEA;
    background-color: #EAEAEA;
    height: 5px;
}

.spaceleft20 {
    margin-left: 20px;
}

.right-align {
    text-align: right;
}

.image-vcenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}    

/*
        body .wizard-header .container {
            height: 100%;
            position: relative;
        }

            body .wizard-header .container figure.logo {
                display: inline-block;
                height: 25px;
                width: auto;
            }

                body .wizard-header .container figure.logo img {
                    height: 100%;
                    width: auto;
                }

            body .wizard-header .container figure.otherlogo {
                bottom: 0;
                line-height: 85px;
                position: absolute;
                right: 15px;
                text-align: right;
                top: 0;
            }

                body .wizard-header .container figure.otherlogo img {
                    width: 70%;
                }

            body .wizard-header .container figure.logo:after {
                background-color: #EAEAEA;
                content: '';
                display: inline-block;
                height: 45px;
                margin: 0 25px;
                position: relative;
                top: 18px;
                width: 2px;
            }
*/

.nomorepadding {
    padding: 0;
}

/*Structure*/

.well {
    padding: 35px 0 35px 34px;
    margin-bottom: 25px;
    border: none;
}

    .well.field-onsite-signature {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        background: inherit;
        box-shadow: inherit;
    }

    .well.title {
        margin-top: 70px;
    }

        .well.title.well-legend .legend {
            border-top-right-radius: 0;
            background-color: transparent;
            border: none;
            border-bottom: 5px solid #345d93;
            color: #345D93;
            cursor: pointer;
            display: block;
            padding: 0 13px;
            z-index: 10;
            position: absolute;
            left: 0;
            top: -44px;
            font: 22px/1.2 HelveticaNeue;
            border-radius: 0;
            vertical-align: middle;
            line-height: 39px;
        }

.legend .glyphicon.collapse-section, .legend .glyphicon.expand-section {
    color: #345D93;
    border: 0;
    font-size: inherit;
    background-color: transparent;
    font-weight: normal;
    padding: 0 8px;
    height: 14px;
    vertical-align: middle;
    font-size: 14px;
    margin-bottom: 9px;
}

/*Blocks*/
.form-group:first-child {
    margin-left: 0;
}

/*Form styles*/
.form-inline .radio {
    margin-left: 20px;
}

    .form-inline .radio label {
        padding-left: 10px !important;
    }

    .form-inline .radio input[type="radio"] {
        position: absolute !important;
    }


/*Titles*/

legend {
    color: #345d93;
    font: 18px 'HelveticaNeue';
    border-color: #c9c9c9;
    margin-bottom: 10px;
    padding-bottom: 6px;
}

.field-label label {
    font: 13px 'HelveticaNeue Italic';
    font-style: italic;
    color: #9e9c9c;
}


.field-name {
    color: rgba(0, 0, 0, .85);
    font: 14px/18px 'HelveticaNeue';
    margin-top: 10px;
    vertical-align: top;
}

.input-field {
    color: #000;
    border-radius: 2px;
    font: 14px 'HelveticaNeue';
}

/* % fields  */

.field-percentage input {
    text-align: center;
    max-width: 58px;
}

.field-percentage .input-group-addon {
    width: initial;
}

/*Group addons*/

span.input-group-addon {
    border: none;
    background: none;
}

.input-group .form-control {
    padding: 0;
}

    .input-group .form-control:first-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }


/*Breadcrums*/

.breadcrumb {
    padding: 10px 15px 0;
    background: transparent;
    margin-bottom: 5px;
    margin-top: 10px;
}

    .breadcrumb li {
        margin-right: 0;
        margin-bottom: 20px;
    }

        .breadcrumb li.complete .tick, .breadcrumb li.incomplete .tick {
            background: url("images/tick.png") no-repeat center;
            background-size: contain;
            bottom: 1px;
            display: none;
            height: 25px;
            position: relative;
            vertical-align: middle;
            width: 15px;
        }

        .breadcrumb li.haserrors .tick {
            background: url("images/error.png") no-repeat center;
            background-size: contain;
            bottom: 1px;
            display: none;
            height: 25px;
            margin-right: 2px;
            position: relative;
            vertical-align: middle;
            width: 15px;
        }

        .breadcrumb li.complete > input, .breadcrumb li.complete > a, .breadcrumb li.incomplete > a, .breadcrumb li.incomplete > input {
            color: #58B743;
        }

        .breadcrumb li.haserrors > input, .breadcrumb li.haserrors > a {
            color: #D82626;
        }
        
        .breadcrumb li .tick {
            display: inline-block !important;
        }

        .breadcrumb li.haserrors {
            border: none !important;
            background-color: initial !important;
        }

    .breadcrumb > li + li::before {
        display: none;
    }

    .breadcrumb > li:not(:last-child)::after {
        content: "";
        display: inline-block;
        background: url("images/bread.png") no-repeat left top;
        background-size: contain;
        width: 6px;
        height: 15px;
        vertical-align: middle;
        margin-left: 9px;
        margin-right: 10px;
        padding: 0 5px;
    }

    .breadcrumb li .step-num {
        display: none;
        width: 25px;
        height: 25px;
        text-align: center;
        font-size: 13px;
        line-height: 25px;
        border-radius: 50%;
        background: #3d5d93;
        font-weight: 700;
        color: #fff;
        vertical-align: middle;
    }

    .breadcrumb li:not(.complete):not(.haserrors):not(.active):not(.incomplete) .step-num {
        color: #9e9c9c;
        display: inline-block;
        height: inherit;
        width: inherit;
        font-size: 14px;
        font-weight: normal;
        line-height: inherit;
        border-radius: inherit;
        background-color: transparent;
    }

    .breadcrumb li:not(.complete):not(.active):not(.incomplete) .step-num::after {
        content: '.';
    }

    .breadcrumb li.active .step-num {
        display: block;
        display: inline-block;
    }

    .breadcrumb li.active a, .breadcrumb li.active input {
        font: 700 14px 'HelveticaNeue';
    }

    .breadcrumb li .btn-link {
        color: #666;
        display: inline-block;
        padding: 0;
    }

    .breadcrumb li.active .btn-link {
        color: #3d5d93;
        opacity: 1;
        vertical-align: middle;
        padding-top: 4px;
        line-height: 100%;
    }

    .breadcrumb li.notfilled {
        min-height: 26px;
    }


/*Checkboxes*/
.checkbox + .checkbox {
    margin-top: 10px;
}

.checkbox input[type="checkbox"] + label {
    margin-top: 0;
    font: 14px/22px 'HelveticaNeue';
}

.checkbox input[type="checkbox"]:focus ~ span {
    border: 1px solid #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    margin: 0 0 0 -2px;
}

/*Selects*/
.select {
    overflow: hidden;
    background: url("images/selectarrow.png") no-repeat right #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.select.ui-state-focus {
    border-color: #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

    .select select {
        background: transparent;
        width: 268px;
        padding: 7px 27px 5px 10px !important;
        border: 0;
        border-radius: 0;
        height: 32px;
        -webkit-appearance: none;
        -moz-appearance: none;
        /*-ms-appearance: none;
        -o-appearance: none;
        appearance: none;*/
        color: black;
        font: 14px 'HelveticaNeue';
        border: none;
    }

        .select select::-ms-expand {
            display: none; /* hide default arrow in IE10 */
        }


        .select select option {
            color: #000;
            -webkit-appearance: none;
            -moz-appearance: none;
            /*-ms-appearance: none;
            -o-appearance: none;
            appearance: none;*/
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            /*-ms-border-radius: 4px;
            -o-border-radius: 4px;*/
            border-radius: 4px;
            border: none;
        }

/*Bottom Navegation*/
.btn-previous-step {
    padding: 11px 0 0 0;
    font: 15px/19px 'HelveticaNeue';
    color: #939393;
    outline: 0 !important;
}

    .btn-previous-step:hover {
        background-color: transparent;
        border-color: transparent;
        color: #939393;
        text-decoration: underline;
    }
/*
    .btn-previous-step:active {
        background: #C9C9C9;
        border-color: #C9C9C9 !important;
        color: #939393;
    }
        */
.btn-other-step {
    background-color: #E0E0E0;
    border: none;
    color: #686868;
    font: 15px/19px 'HelveticaNeue';
    outline: 0 !important;
    padding: 12px 31px 9px 26px;
}

    .btn-other-step:hover {
        background: #E6E6E6;
        border-color: #E6E6E6 !important;
        color: #686868;
    }

    .btn-other-step:active {
        background: #C9C9C9;
        border-color: #C9C9C9 !important;
        color: #686868;
    }

.btn-next-step {
    border-color: #345D93;
    padding: 11px 31px 8px 26px;
    background: #345d93;
    border-radius: 4px;
    color: #fff;
    font: 15px/19px 'HelveticaNeue';
    outline: 0 !important;
}

    .btn-next-step:hover {
        background: #5C7DA8;
        border-color: #5C7DA8 !important;
        color: white;
    }

    .btn-next-step:active {
        background: #294A75;
        border-color: #294A75 !important;
        color: white;
    }

.btn-submit-step {
    border-color: #3EAB48;
    padding: 11px 31px 8px 26px;
    background: #3EAB48;
    border-radius: 4px;
    color: #fff;
    font: 15px/19px 'HelveticaNeue';
    outline: 0 !important;
}

    .btn-submit-step:hover {
        background: #64BB6C;
        border-color: #64BB6C !important;
        color: white;
    }

    .btn-submit-step:active {
        background: #318839;
        border-color: #318839 !important;
        color: white;
    }

.copyrights {
    text-align: center;
    color: #9f9e9e;
    font: 12px 'HelveticaNeue';
}

/*Field Group*/
.field-group {
    margin-top: 19px;
}

/*Labels*/
label.checkbox-inline {
    font-weight: normal;
}

/*Errors*/
.error-review {
    background-color: rgb(237, 105, 105);
    border-radius: 3px;
    color: white;
    margin-bottom: 30px;
    padding: 5px 0;
    text-align: center;
    width: calc(100% - 34px);
}

    .error-review span {
        font-weight: bold;
    }

.error {
    background-color: #FEF9EB !important;
    border: 1px solid #C10C0C !important;
}

.haserrors {
    background-color: #FEF9EB !important;
    border: 1px solid #C10C0C !important;
}

.defaultPackageHighlight {
    font-weight: bold;
}

/*Bsecial buttons*/
.field-add-group, .field-delete-group {
    margin-bottom: 20px;
    font-size: 10px;
    height: 32px;
}

.text-danger {
    color: #C10C0C;
    font-size: 13px;
}

/*Bottom buttons*/
.button-bottom {
    padding: 0 19px;
    text-align: right;
}

    .button-bottom .prev {
        background-color: transparent;
        border: none;
        float: left;
    }

    .button-bottom > input:nth-child(n+1):not(:last-child) {
        margin-right: 10px;
    }

/*Footer*/
footer.copyrights {
    border-top: 1px solid #c9c9c9;
    margin-top: 25px;
    padding-top: 20px;
}

    footer.copyrights p {
        margin: 0;
    }

/*Fields*/
.input-group-addon {
    color: #9F9E9E;
    padding: 6px 9px;
}

.form-group.field.field-label {
    margin-bottom: 0;
}

@media (max-width: 991px) {
    body .wizard-header .container h1 {
        font-size: 22px;
        line-height: 26px;
        margin-top: 15px;
        width: 40%;
        word-wrap: break-word;
    }

    body .wizard-header .container figure.otherlogo img {
        width: 56%;
    }
}

@media (min-width: 1200px) {
    .btn-previous-step {
        border-color: #fff;
    }
}

@media (max-width: 767px) {
    header .container {
        padding-left: 0;
        padding-right: 0;
    }
}